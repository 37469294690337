import Blueprint from "$blueprint/js/blueprint";
import Animations from "$blueprint/js/animations/index";
import Forms from "$blueprint/js/forms/index";

Blueprint().then(blueprint => {
    if (blueprint.isWebsite){
        Animations.create().then(Animations.swup(blueprint.swup));
        Forms.create().then(Forms.swup(blueprint.swup));
    }
});
