export default {
    observerMutations: null,
    observerIntersections: null,
    elements: [],

    /**
     * create()
     * Observes selector and adds ".show" class when in view of browser window.
     * Also checks for DOM changes, updating internal list of elements
     * that match the selector.
     *
     * @param  {Element} elementContainer
     * @param  {String} [selector=".reveal"]
     * @return {Promise}
     */
    create(elementContainer = document.querySelector("#page"), selector = ".reveal"){
        return new Promise((resolve, reject) => {
            this.destroy().then(() => {

                try {

                    this.observerMutations = new MutationObserver(mutations => {
                        mutations.forEach(mutation => {
                            var hasChangedElements = false;

                            console.log("mutation", mutation);

                            if (mutation.addedNodes){
                                mutation.addedNodes.forEach(node => {
                                    if (node.matches(selector)){
                                        hasChangedElements = true;
                                    }
                                });
                            }

                            if (hasChangedElements){
                                this.elements = document.querySelectorAll(selector);
                                this.restartObserverIntersections();
                            }
                        });
                    });

                    this.observerMutations.observe(elementContainer, {
                        attributes: true,
                        childList: true,
                        characterData: true,
                    });

                    this.elements = document.querySelectorAll(selector);

                    this.restartObserverIntersections();

                } catch (error){
                    console.error(error);
                    reject(error);
                }

                resolve();

            }).catch(reject);
        });
    },

    /**
     * Destroy()
     * destroy all animation JS resources. Doesn't clean up classes
     * @return {Promise}
     */
    destroy(){
        return new Promise((resolve, reject) => {
            this.elements = null;

            if (this.observerMutations){
                this.observerMutations.disconnect();
            }

            if (this.observerIntersections){
                this.observerIntersections.disconnect();
            }

            this.observerMutations = null;
            this.observerIntersections = null;

            resolve();
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },

    restartObserverIntersections(){
        return new Promise((resolve, reject) => {

            try {

                if (this.observerIntersections){
                    this.observerIntersections.disconnect();
                    this.observerIntersections = null;
                }

                if (this.elements){
                    this.observerIntersections = new IntersectionObserver(items => {
                        items.forEach(item => {
                            if (
                                (item.boundingClientRect.y < window.innerHeight * 0.9) ||
                                (item.boundingClientRect.height >= window.innerHeight)
                            ){
                                item.target.classList.add("show");
                            }
                        });
                    }, {
                        threshold: [0.05, 0.125, 0.2, 0.5, 0.8, 0.875, 0.95],
                    });

                    this.elements.forEach(element => {
                        this.observerIntersections.observe(element);
                    });
                }

                resolve();
            }
            catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}
